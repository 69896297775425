@import 'mixins';
@import 'fonts';
@import 'colors';

/* fonts family */
$latoFont: Lato, sans-serif;

/* fonts size */
$lightFontWeight: 300;
$normalFontWeight: 400;
$boldFontWeight: 700;

/*lato*/
.lato {
  &-66 {
    &-l {
      @include text-style(66px, $ghost, Lato, $lightFontWeight);
      &-vw {
        @include text-style(3.438vw, $ghost, Lato, $lightFontWeight);
      }
    }
    &-n {
      @include text-style(66px, $ghost, Lato, $normalFontWeight);
      &-vw {
        @include text-style(3.438vw, $ghost, Lato, $normalFontWeight);
      }
    }
    &-b {
      @include text-style(66px, $ghost, Lato, $boldFontWeight);
      &-vw {
        @include text-style(3.438vw, $ghost, Lato, $boldFontWeight);
      }
    }
  }

  &-50 {
    &-l {
      @include text-style(50px, $ghost, Lato, $lightFontWeight);
      &-vw {
        @include text-style(2.604vw, $ghost, Lato, $lightFontWeight);
      }
    }
    &-n {
      @include text-style(50px, $ghost, Lato, $normalFontWeight);
      &-vw {
        @include text-style(2.604vw, $ghost, Lato, $normalFontWeight);
      }
    }
    &-b {
      @include text-style(50px, $ghost, Lato, $boldFontWeight);
      &-vw {
        @include text-style(2.604vw, $ghost, Lato, $boldFontWeight);
      }
    }
  }

  &-46 {
    &-l {
      @include text-style(46px, $ghost, Lato, $lightFontWeight);
      &-vw {
        @include text-style(2.396vw, $ghost, Lato, $lightFontWeight);
      }
    }
    &-n {
      @include text-style(46px, $ghost, Lato, $normalFontWeight);
      &-vw {
        @include text-style(2.396vw, $ghost, Lato, $normalFontWeight);
      }
    }
    &-b {
      @include text-style(46px, $ghost, Lato, $boldFontWeight);
      &-vw {
        @include text-style(2.396vw, $ghost, Lato, $boldFontWeight);
      }
    }
  }

  &-42 {
    &-l {
      @include text-style(42px, $ghost, Lato, $lightFontWeight);
      &-vw {
        @include text-style(2.188vw, $ghost, Lato, $lightFontWeight);
      }
    }
    &-n {
      @include text-style(42px, $ghost, Lato, $normalFontWeight);
      &-vw {
        @include text-style(2.188vw, $ghost, Lato, $normalFontWeight);
      }
    }
    &-b {
      @include text-style(42px, $ghost, Lato, $boldFontWeight);
      &-vw {
        @include text-style(2.188vw, $ghost, Lato, $boldFontWeight);
      }
    }
  }

  &-38 {
    &-l {
      @include text-style(38px, $ghost, Lato, $lightFontWeight);
      &-vw {
        @include text-style(1.979vw, $ghost, Lato, $lightFontWeight);
      }
    }
    &-n {
      @include text-style(38px, $ghost, Lato, $normalFontWeight);
      &-vw {
        @include text-style(1.979vw, $ghost, Lato, $normalFontWeight);
      }
    }
    &-b {
      @include text-style(38px, $ghost, Lato, $boldFontWeight);
      &-vw {
        @include text-style(1.979vw, $ghost, Lato, $boldFontWeight);
      }
    }
  }

  &-32 {
    &-l {
      @include text-style(32px, $ghost, Lato, $lightFontWeight);
      &-vw {
        @include text-style(1.667vw, $ghost, Lato, $lightFontWeight);
      }
    }
    &-n {
      @include text-style(32px, $ghost, Lato, $normalFontWeight);
      &-vw {
        @include text-style(1.667vw, $ghost, Lato, $normalFontWeight);
      }
    }
    &-b {
      @include text-style(32px, $ghost, Lato, $boldFontWeight);
      &-vw {
        @include text-style(1.667vw, $ghost, Lato, $boldFontWeight);
      }
    }
  }

  &-30 {
    &-l {
      @include text-style(30px, $ghost, Lato, $lightFontWeight);
      &-vw {
        @include text-style(1.563vw, $ghost, Lato, $lightFontWeight);
      }
    }
    &-n {
      @include text-style(30px, $ghost, Lato, $normalFontWeight);
      &-vw {
        @include text-style(1.563vw, $ghost, Lato, $normalFontWeight);
      }
    }
    &-b {
      @include text-style(30px, $ghost, Lato, $boldFontWeight);
      &-vw {
        @include text-style(1.563vw, $ghost, Lato, $boldFontWeight);
      }
    }
  }

  &-28 {
    &-l {
      @include text-style(28px, $ghost, Lato, $lightFontWeight);
      &-vw {
        @include text-style(1.458vw, $ghost, Lato, $lightFontWeight);
      }
    }
    &-n {
      @include text-style(28px, $ghost, Lato, $normalFontWeight);
      &-vw {
        @include text-style(1.458vw, $ghost, Lato, $normalFontWeight);
      }
    }
    &-b {
      @include text-style(28px, $ghost, Lato, $boldFontWeight);
      &-vw {
        @include text-style(1.458vw, $ghost, Lato, $boldFontWeight);
      }
    }
  }

  &-26 {
    &-l {
      @include text-style(26px, $ghost, Lato, $lightFontWeight);
      &-vw {
        @include text-style(1.354vw, $ghost, Lato, $lightFontWeight);
      }
    }
    &-n {
      @include text-style(26px, $ghost, Lato, $normalFontWeight);
      &-vw {
        @include text-style(1.354vw, $ghost, Lato, $normalFontWeight);
      }
    }
    &-b {
      @include text-style(26px, $ghost, Lato, $boldFontWeight);
      &-vw {
        @include text-style(1.354vw, $ghost, Lato, $boldFontWeight);
      }
    }
  }

  &-25 {
    &-l {
      @include text-style(25px, $ghost, Lato, $lightFontWeight);
      &-vw {
        @include text-style(1.302vw, $ghost, Lato, $lightFontWeight);
      }
    }
    &-n {
      @include text-style(25px, $ghost, Lato, $normalFontWeight);
      &-vw {
        @include text-style(1.302vw, $ghost, Lato, $normalFontWeight);
      }
    }
    &-b {
      @include text-style(25px, $ghost, Lato, $boldFontWeight);
      &-vw {
        @include text-style(1.302vw, $ghost, Lato, $boldFontWeight);
      }
    }
  }

  &-24 {
    &-l {
      @include text-style(24px, $ghost, Lato, $lightFontWeight);
      &-vw {
        @include text-style(1.25vw, $ghost, Lato, $lightFontWeight);
      }
    }
    &-n {
      @include text-style(24px, $ghost, Lato, $normalFontWeight);
      &-vw {
        @include text-style(1.25vw, $ghost, Lato, $normalFontWeight);
      }
    }
    &-b {
      @include text-style(24px, $ghost, Lato, $boldFontWeight);
      &-vw {
        @include text-style(1.25vw, $ghost, Lato, $boldFontWeight);
      }
    }
  }

  &-22 {
    &-l {
      @include text-style(22px, $ghost, Lato, $lightFontWeight);
      &-vw {
        @include text-style(1.146vw, $ghost, Lato, $lightFontWeight);
      }
    }
    &-n {
      @include text-style(22px, $ghost, Lato, $normalFontWeight);
      &-vw {
        @include text-style(1.146vw, $ghost, Lato, $normalFontWeight);
      }
    }
    &-b {
      @include text-style(22px, $ghost, Lato, $boldFontWeight);
      &-vw {
        @include text-style(1.146vw, $ghost, Lato, $boldFontWeight);
      }
    }
  }

  &-20 {
    &-l {
      @include text-style(20px, $ghost, Lato, $lightFontWeight);
      &-vw {
        @include text-style(1.042vw, $ghost, Lato, $lightFontWeight);
      }
    }
    &-n {
      @include text-style(20px, $ghost, Lato, $normalFontWeight);
      &-vw {
        @include text-style(1.042vw, $ghost, Lato, $normalFontWeight);
      }
    }
    &-b {
      @include text-style(20px, $ghost, Lato, $boldFontWeight);
      &-vw {
        @include text-style(1.042vw, $ghost, Lato, $boldFontWeight);
      }
    }
  }

  &-18 {
    &-l {
      @include text-style(18px, $ghost, Lato, $lightFontWeight);
      &-vw {
        @include text-style(0.938vw, $ghost, Lato, $lightFontWeight);
      }
    }
    &-n {
      @include text-style(18px, $ghost, Lato, $normalFontWeight);
      &-vw {
        @include text-style(0.938vw, $ghost, Lato, $normalFontWeight);
      }
    }
    &-b {
      @include text-style(18px, $ghost, Lato, $boldFontWeight);
      &-vw {
        @include text-style(0.938vw, $ghost, Lato, $boldFontWeight);
      }
    }
  }

  &-16 {
    &-l {
      @include text-style(16px, $ghost, Lato, $lightFontWeight);
      &-vw {
        @include text-style(0.833vw, $ghost, Lato, $lightFontWeight);
      }
    }
    &-n {
      @include text-style(16px, $ghost, Lato, $normalFontWeight);
      &-vw {
        @include text-style(0.833vw, $ghost, Lato, $normalFontWeight);
      }
    }
    &-b {
      @include text-style(16px, $ghost, Lato, $boldFontWeight);
      &-vw {
        @include text-style(0.833vw, $ghost, Lato, $boldFontWeight);
      }
    }
  }

  &-14 {
    &-l {
      @include text-style(14px, $ghost, Lato, $lightFontWeight);
      &-vw {
        @include text-style(0.729vw, $ghost, Lato, $lightFontWeight);
      }
    }
    &-n {
      @include text-style(14px, $ghost, Lato, $normalFontWeight);
      &-vw {
        @include text-style(0.729vw, $ghost, Lato, $normalFontWeight);
      }
    }
    &-b {
      @include text-style(14px, $ghost, Lato, $boldFontWeight);
      &-vw {
        @include text-style(0.729vw, $ghost, Lato, $boldFontWeight);
      }
    }
  }

  &-12 {
    &-l {
      @include text-style(12px, $ghost, Lato, $lightFontWeight);
      &-vw {
        @include text-style(0.625vw, $ghost, Lato, $lightFontWeight);
      }
    }
    &-n {
      @include text-style(12px, $ghost, Lato, $normalFontWeight);
      &-vw {
        @include text-style(0.625vw, $ghost, Lato, $normalFontWeight);
      }
    }
    &-b {
      @include text-style(12px, $ghost, Lato, $boldFontWeight);
      &-vw {
        @include text-style(0.625vw, $ghost, Lato, $boldFontWeight);
      }
    }
  }

  &-10 {
    &-l {
      @include text-style(10px, $ghost, Lato, $lightFontWeight);
      &-vw {
        @include text-style(0.521vw, $ghost, Lato, $lightFontWeight);
      }
    }
    &-n {
      @include text-style(10px, $ghost, Lato, $normalFontWeight);
      &-vw {
        @include text-style(0.521vw, $ghost, Lato, $normalFontWeight);
      }
    }
    &-b {
      @include text-style(10px, $ghost, Lato, $boldFontWeight);
      &-vw {
        @include text-style(0.521vw, $ghost, Lato, $boldFontWeight);
      }
    }
  }
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}
