@import './colors';
@import './font-style';

$headerHeight: 8.7vh;
$ui-tools-height: 7vh;
$footerHeight: 7vh;
$sidenavWidth: 13vw;
$sidenavHeight: calc(100% - #{$headerHeight});
$mainViewHeight: calc(100% - #{$headerHeight + $footerHeight});

$entityCardWidth: 27.5vw;
$entityCardHeight: 24vh;
$attacksMapHeight: 32vh;

.horizontal-separator {
  width: 100%;
  height: 1px;
  min-height: 1px;
  background-color: $mirage;
}

.flex-dc {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.flex-full-dc {
  display: flex;
  height: 100%;
  width: 100%;
}

.pos-relative {
  position: relative;
}

.pos-abs {
  position: absolute;
}

.vertical-separator {
  width: 1px;
  min-width: 1px;
  background-color: $mirage;
}

.btn-type-link {
  align-self: flex-end;
  margin-right: 10px;
  background: none;
  border: none;
  padding: 0;
  @include text-style(0.73vw, $bondiBlue, Lato, $normalFontWeight);
  color: $pictonBlue;
  cursor: pointer;
  outline: none;

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
}

.score {
  &-high {
    color: $scoreHigh;

    &-bg {
      background-color: $scoreHigh;
    }

    &-stroke {
      stroke: $scoreHigh;
      fill: $scoreHigh;
    }
  }

  &-medium {
    color: $scoreMedium;

    &-bg {
      background-color: $scoreMedium;
    }

    &-stroke {
      stroke: $scoreMedium;
      fill: $scoreMedium;
    }
  }

  &-low {
    color: $scoreLow;

    &-bg {
      background-color: $scoreLow;
    }

    &-stroke {
      stroke: $scoreLow;
      fill: $scoreLow;
    }
  }
}

//* CyberArk Related color classes
.cyberArk-score {
  &-high {
    color: $cyberArkScoreHigh;

    &-bg {
      background-color: $cyberArkScoreHigh;
    }

    &-stroke {
      stroke: $cyberArkScoreHigh;
      fill: $cyberArkScoreHigh;
    }
  }

  &-medium {
    color: $cyberArkScoreMedium;

    &-bg {
      background-color: $cyberArkScoreMedium;
    }

    &-stroke {
      stroke: $cyberArkScoreMedium;
      fill: $cyberArkScoreMedium;
    }
  }

  &-low {
    color: $cyberArkScoreLow;

    &-bg {
      background-color: $cyberArkScoreLow;
    }

    &-stroke {
      stroke: $cyberArkScoreLow;
      fill: $cyberArkScoreLow;
    }
  }
}

.bnb-score {
  &-highest {
    color: $azureRadiance;

    &-bg {
      background-color: $azureRadiance;
    }

    &-stroke {
      stroke: $azureRadiance;
      fill: $azureRadiance;
    }
  }

  &-high {
    color: $pastelGreen;

    &-bg {
      background-color: $pastelGreen;
    }

    &-stroke {
      stroke: $pastelGreen;
      fill: $pastelGreen;
    }
  }

  &-medium {
    color: $bnbGold;

    &-bg {
      background-color: $bnbGold;
    }

    &-stroke {
      stroke: $bnbGold;
      fill: $bnbGold;
    }
  }

  &-low {
    color: $tangerine;

    &-bg {
      background-color: $tangerine;
    }

    &-stroke {
      stroke: $tangerine;
      fill: $tangerine;
    }
  }

  &-lowest {
    color: $bnbRed;

    &-bg {
      background-color: $bnbRed;
    }

    &-stroke {
      stroke: $bnbRed;
      fill: $bnbRed;
    }
  }
}

.priority {
  &-critical {
    color: $salCritical;

    &-bg {
      background-color: $salCritical;
    }
  }

  &-high {
    color: $salHigh;

    &-bg {
      background-color: $salHigh;
    }
  }

  &-medium {
    color: $salMedium;

    &-bg {
      background-color: $salMedium;
    }
  }

  &-low {
    color: $salLow;

    &-bg {
      background-color: $salLow;
    }
  }
}

text>tspan.lato-24-n,
text>tspan.lato-21-l {
  stroke: none !important;
}

/* NgbModule - ngbTab override css */
.tabset {
  width: 100%;

  ul.nav-tabs {
    border-bottom: 2px solid $steelGray;

    .nav-link {
      color: $ghost;
      background-color: $transparent;
      padding: 0.5rem 1.3rem;
      margin: 0 0.7rem;

      &:hover {
        border-color: $transparent;
      }

      &.active {
        background-color: $transparent;
        border-color: $transparent;
        color: $pictonBlue;
        border-bottom: 2px solid $pictonBlue;

        span {
          color: $pictonBlue;
        }
      }

      &.disabled {
        color: $paleSky;

        span {
          color: $paleSky;
        }
      }
    }
  }

  .tab-content {
    height: calc(100% - 44px);
    color: $ghost;

    .tab-pane {
      height: 100%;
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: $ghost !important;
}

select {
  -webkit-appearance: button;
  -webkit-border-radius: 2px;
  -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  -webkit-padding-end: 20px;
  -webkit-padding-start: 2px;
  -webkit-user-select: none;
  background-image: url(/assets/svg/select-arrow.svg), -webkit-linear-gradient($ebonyClay, $ebonyClay, $ebonyClay);
  background-position: 90% center;
  background-repeat: no-repeat;
  background-color: $ebonyClay;
  border: 0;
  color: $ghost;
  font-size: inherit;
  overflow: hidden;
  padding: 5px 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 180px;
  height: 35px;
  text-align: center;
  text-align-last: center;
  border-radius: 6px;

  &:focus {
    color: $ghost;
    outline: none;
    box-shadow: none;
  }

  option {
    background-color: $ebonyClay;
    color: $ghost;
  }
}

.section-name {
  color: $white;
}

.pointer {
  cursor: pointer;
}

.disabled_btn {
  opacity: 0.5;
  cursor: default !important;
}

.hide-me {
  display: none !important;
}

//====== buttons css ========
.standard-btn {
  @extend .lato-14-n-vw;
  height: 3vh;
  width: 5.15vw;
  border-radius: 3px;
  background: $pictonBlue 0% 0% no-repeat padding-box;
  color: $white;
  border: 1px solid $pictonBlue;
  outline: none;
  cursor: pointer;

  &.flex-c {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.standard-btn-transparent {
  @extend .lato-14-n-vw;
  @extend .standard-btn;
  background: $transparent 0% 0% no-repeat padding-box;
  border: 1px solid $white;
}

.standard-btn-grey {
  @extend .lato-14-n-vw;
  @extend .standard-btn;
  background: $black5 0% 0% no-repeat padding-box;
  border: 1px solid $black5;
}

.txt-white {
  color: $white !important;
}

.txt-blue {
  color: $pictonBlue !important;
}

// Use this class in the parent tag/div of [ngbTooltip]
.tooltip-style {
  ::ng-deep {
    .info-tooltip {
      justify-content: center;
      display: flex;
      width: 24vw;
      display: flex;
      justify-content: center;

      .tooltip-inner {
        padding: 0.6vh 1vw;
        max-width: fit-content;
        margin: 0 1vw;
        @extend .lato-16-n-vw;
        color: $white;
      }
    }
  }
}

.abs-centre {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mandatory-asterisk {
  display: flex;
}

.required-asterisk {
  color: $ruby-red !important;
  font-weight: bold;
  margin-left: 0.2vw;
  font-size: 1.2em;
  margin-top: -0.4vh;
}

.no-subentity-message {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

// For adding indentation on dynamic html
// content coming from r7
.r7-ul-custom {
  padding-inline-start: 11px;
}

.disabled-view {
  pointer-events: none;
  opacity: 0.5;
}

::ng-deep .large-number-tooltip {
  min-width: fit-content !important;
  opacity: 1 !important;

  .tooltip-inner {
    min-width: fit-content !important;
    background-color: $brightGrayLight;
    padding: 0.1vh 0.4vw;
  }

  .arrow {
    &::before {
      border-top-color: $brightGrayLight;
    }
  }
}