/* colors - according to http://chir.ag/projects/name-that-color/ */
$transparent: transparent;
$white: #ffffff;
$black: #000000;
$black2: #000207;
$black3: #050d21;
$black4: #000000c3;
$black5: #151a2f;
$black6: #3a4662;
$black7: #151c2c;
$black8: #080d1e61;
$black9: #000000a1;
$black10: #000000a0;
$black11: #080b13;
$black12: #00000076;
$black13: #050505;
$merelyBlack: #161a2c;
$biscay: #203769;
$chineseBlack: #0b1124;
$chineseBlack1: #0f1019;
$red: #ff0000;
$red1: #f8241a;
$red2: #ff2700;

$blackShadow: #000000b5;
$blackShadow2: #00000095;
$blackShadow3: #00000060;
$blackShadow4: #040921e6;
$aubergine: #1a040c;
$gray: #808080;
$white10: #ffffff1a;

$harborBlue: #2b354e;
$blue1: #0f3750;
$blue2: #0b1b39;
$blue3: #161b33;
$orange: #ff9300;
$darkBlueShade: #161f2f;
$limeGreen: #dde600;
$shamRockGreen: #1bd964;
$orangePeel: #ff9300;
$mirageCinder: #141723;

$oldNavy: #161c2a;
$oldNavy2: #161c2c;
$oldNavyDark: #0f121c;
$blackRussian: #000522;
$alabaster: #f7f7f7;
$wildSand: #f4f4f4;
$atoll: #3197ee;
$tealBlue: #084871;
$amaranth: #ea295b;
$violetRed: #f53d7d;
$scarlet: #f81d12;
$blush: #bc435f;
$deepBlush: #e36683;
$yellowOrange: #fda940;
$treePoppy: #fd9823;
$yellowDirtyFellow: #f4d155;
$malachite: #1bd964;
$bottleGreen: #073418;
$bondiBlue: #0a9bad;
$vulcan: #121521;
$vulcanLight: #090b13;
$vulcan60: rgba(33, 36, 47, 0.6);
$vulcanLight60: rgb(18, 21, 33, 0.6);
$manatee: #8c929d;
$vulcanDark: #0a0c14;
$vulcan7: #0d0f1a;
$vulcanHover: #0e1018;
$blueDianne: #204d5c;
$melanzane: #290411;
$shuttleGray: #5e6374;
$shuttleGray1: #606876;
$mirage: #171a27;
$MirageSoft: #1b1f2b;
$brightRed: #b40900;
$mirageMedium: #171b24;
$mirageDark: #191c26;
$mirageAvatar: #1d2033;
$mirageCollection: #1a2336;
$mirageBoard2: #1a1f2f;
$mirageTierCard: #151b31;
$mirageGrey: #171b2f;
$mirage1: #202341;
$mirage2: #141628;
$mirage3: #161925;
$mirage4: #161928;
$mirage5: #141724;
$mirage6: #141624;
$mirage7: #131a2b;
$downriver: #0c3152;
$doveGrey: #707070;
$trout: #484c57;
$trout1: #505565;
$vermilion: #f7490a;
$tahitiGold: #e57e06;
$violentViolet: #34095f;
$pinkPurple: #8e1ed973;
$pinkPurpleBright: #8e1ed9;
$pinkPurpleBright44: rgb(142, 30, 217, 0.44);
$seance: #5c14a3;
$camelot: #902f56;
$cello: #1E315A;
$ebonyClay: #1b1e29;
$ebonyClay1: #232733;
$ebonyClay2: #1C2229;
$ebonyClayLight: #1e212b;
$ebonyBorder: #0d1221;
$attackEbonyClay: #1c1f29;
$boardEbonyClay: #242a3c;
$ebonyClayMedium: #1c202c;
$ebonyDark: #222636;
$ebonyAlert: #0b0e20;
$waterloo: #7f8495;
$mercury: #e6e6e6;
$haiti: #0b1028;
$gallery: #efefef;
$shark: #1b1b23;
$shark2: #20232b;
$shark3: #1a1c1f;
$ebony: #0e1322;
$ebony2: #03050a;
$ebonyClay: #23293e;
$darkMirage2: #191c26;
$lightShadow: #0a101f9a;
$ebony2Light: rgba(3, 5, 10, 0.56);
$avatarBackground: rgb(38, 42, 54);
$ebony3: #0d1123ab;
$ebony4: #111628;
$ebony5: #090e1c;
$ebony6: #0e0c1a;
$ebony7: #0d1326;
$ebony8: #0a0d1d;
$ebony9: #06070d;
$ebony10: #0a0f1a;
$scorpion: #5f5f5f;
$ebony11: #0b1021;
$ebony12: #0d1123;
$ebony13: rgb(3, 5, 10, 0.33);
$ebony14: #0f1529;
$ebony15: #0c1022;
$ebony16: #03050b;
$ebony17: #0d1227;
$darkCharade: #171a26;
$darkCharade1: #292d39;
$lighterCharade: #292c39;
$geryClay: #1e2338;
$pinkGradient: #9a0337;
$lightCharade: #1b1e2b;
$redGradient: #ea433a;
$alizarin: #d92e26;
$lighterOrange: #fca500;
$lochmara: #0c82d1;
$charade: #21242f;
$charadeLight: #2d313f;
$charade80: #21242fd9;
$purple: #6a11c3;
$purpleHeartRgba: rgba(102, 42, 172, 0.71);
$jaggerRgba: rgba(52, 14, 96, 0.52);
$vibrantPurple: #780fc0;
$mediumPurple: #9f67e0;
$helioTrope: #c552f0;
$mediumPurple1: #9b52f0;
$mediumPurpleDark: #924be4;
$pumpkin: #fd8223;
$carnation: #f75f57;
$carnation2: #f77057;
$alizarinCrimson: #e6251b;
$dodgerBlue: #1fcbff;
$ruskBlue: #1362f8;
$ruskBlue12: rgb(19, 98, 248, 0.12);
$energyYellow: #f7d857;
$energyYellow2: #dff730;
$energyYellowDark: #f7d257;
$tulipTree: #e8b244;
$brightPictonBlue: #146dfc;
$turquoiseBlue: #1bd964;
$headerBg: #1f222c;
$headerBg65: #1f222c65;
$logoBoxShadow: rgba(0, 0, 0, 0.36);
$steelGray: #1c1e28;
$steelGrayLight: #1c1c2b;
$steelGrayMix: #1c1c2baa;
$steelGrayMedium: #1f222c;
$steelGrayMediumWithOpacity: #1f222c47;
$placeHolderColor: #757575;
$steelGrayDark: #1a1d26;
$vulcan2: #12151f;
$cerulean: #0f92e3;
$cinder: #0f0f1a;
$pictonBlue: #29a9eb;
$pictonBlueBright: #057df7;

$pictonBlueLight: #1eb9e6;
$pickledBlueWood: #2a334e;
$headerGradientDark: #008ed8;
$headerGradientLight: #48a9dd;
$scienceBlue: #054dd5;
$baliHai: #809eb1;
$veniceBlue: #04547e;
$chathamsBlue: #155576;
$catinBlue: #0a6099;
$darkBlue: #000615;
$newDarkBlue: #08061b;
$ghost: #c5c9d1;
$dimghost: #c5c9d173;
$dullGray2: #111519;
$ghostDull: #c5c9d140;
$ghostDull2: rgba(197, 201, 209, 0.2);
$ghostOp: rgba(197, 201, 209, 0.38);
$ghostOp41: rgb(197, 201, 209, 0.41);
$ghostOp60: rgb(197, 201, 209, 0.6);
$lightGhost: #e4e6e7;
$WildStrawberry: #ff3b7e;
$claret: #882043;
$claret2: #801e3f;
$blackPearl: #01050b;
$blackPearlLightest: #020409;
$blackPearlLight: #05091c;
$blackPearl75: rgb(5, 9, 28, 0.75);
$blackPearlLight77Opacity: #05091cc4;
$blackPearlMedium: #030715;
$blackPearlDark: #050d21;
$CornflowerBlue: #0b517cf3;
$blackPearlFade: #070b22;
$blackPearl1: #05091A;
$ebonyClay: #1e212c;
$haiti: #080d1f;
$haitiOpacity: #080d1fed;
$haitiDark: #0c1129;
$woodSmoke: #12161a;
$woodSmokeDark: #111117;
$woodSmokeDark1: #111217;
$woodSmokeDark2: #12161a;
$woodSmokeDark3: #12161a;
$darkGrey2: #00000038;
$boulder: #777777;
$paleSky: #6c757d;
$brightGray: #3b4155;
$brightGray2: rgb(58, 62, 75, 0.4);
$brightGrayLight: #3a3e4a;
$blueCharcoal: #000517;
$dullGray: rgb(58, 62, 75, 0.47);
$blueCharcoalLight: #000102;
$blueCharcoal1: #000306;
$blueCharcoalDark: #010517;
$blueCharcoalShadow: #01050d;
$cornflowerBlue: #00000029;
$cornflowerBlueLight: #6195ed;
$silverChalice: #a9a9a9;
$aquaGradient: #0de3ff;
$mediumSlateBlue: #9545f4;
$mischkaapprox: #d8dbe3;
$darkPurple: #5808b5;
$salem: #089b57;
$burgundy: #760029;
$lighterPurple: #a962fc;
$yellowGradient: #e3b809;
$aqua: #0ee8fa;
$jazzberryJam: #b10540;
$lightBlueGradient: #07b0d9;
$violetGradient: #6617c1;
$darkRedGradient: #620b2a;
$dustyGray: #999999;
$mediumRedVoilet: #b93293;
$denim: #0b76b9;
$darkMirage: #131726;
$lightMirage: #151826;
$darkSlate: #0b0e17;
$widgetBackground: #1d202b;
$pictonBlueDull: rgba(41, 169, 235, 0.5);
$pictonBlueDull1: rgba(41, 169, 235, 0.2);
$colorSliverdull: #c5c9d1;
$externalScanCleared: #18d360;
$searchBottomBorder: #181b22;
$primaryBackgroundDull: #272a36;
$section-background: #1d202b;
$threatIntelligenceCircle: #111522;
$threatIntelligenceCircleTransparency: #11141f99;
$postureCircleBackground: #23222b;
$subMenuLightFont: $waterloo;
$financialTableRow: #171a22;
$aluminium: #a8adb7;
$mountainMedow: #1bd981;
$greenHaze: #00914e;
$circularProgressColor: $blackPearl;
$shinyBlack: #090b15;
$circularProgressRgba: $turquoiseBlue;
$violetRedRgba: rgba(245, 61, 125, 0.89);
$melanzaneRgba: rgba(41, 4, 17, 0.89);
$blueGradient: linear-gradient($veniceBlue, $pictonBlue);
$purpleGradient: linear-gradient($purple, $purpleHeartRgba, $jaggerRgba);
$bordeauxGradient: linear-gradient($violetRedRgba, $melanzaneRgba);
$spiderChartBlueGradient: linear-gradient($pictonBlue, $chathamsBlue);
$spiderChartBordeauxGradient: linear-gradient($claret, $claret, $claret2);
$remediationCurtain: #272b37;
$securityControlCard: $ebonyClay;
$securityControlCardSelected: #191b24;
$clientCardLogoDivider: #1c1e29;
$cardinal: #c71b40;
$bigStone: #10182f;
$bigStone1: #161f3c;
$bigStone5: #182139;
$bigStone75: rgba(22, 31, 60, 0.75);
$elephant: #0a2638;
$primaryBackground: #2e3240;
$trout2: #4c4f5a;
$primaryBkg55: rgba(46, 50, 64, 0.55);
$primaryBk24: rgb(46, 50, 64, 0.24);
$woodSmoke67: rgb(17, 18, 24, 0.67);
$radicalRed: #ff3d6b;
$cyan: #10d0ff;
$tuna: #313443;
$tuna53: rgba(49, 52, 67, 0.53);
$tunaDark: #353639;
$tunaDarker: #333743;
$aquamarine: #54feff;
$eden: #103c5d;
$blackPearlExScan: #052036;
$wedgwood: #44849c;
$paleGrey: #b2b6bf;
$bluishGrey: #131928;
$blakishBlue: #080d1e;
$milanoRed: #c10d04;
$milanoRedDark: #cb2506;
$goldenGrass: #dfb91c;
$iris: #964ced;
$deepGrey: #151820;
$darkDeepGrey: #151721;
$deepGreyBlue: #0d1222;
$olympic: #1e88c2;
$deepDarkGrey: #06061c;
$darkGrey: #3a4052;
$lamePink: #ff98b0;
$lameYellow: #f0cf8e;
$lameWhite: #e2e5ea;
$skinnyPink: #fdafab;
$lameBlue: #93b5f7;
$lameGrey: #7e7e80;
$lameWhite: #ffffff7a;
$grandmaGrey: #c5c9d161;
$spunPearlOpac: #a9a9acb3;
$neatBlue: #082642;
$nearToBlack: #00000017;
$almostBlack: #03050a52;
$mintGreen: #92cb4f;
$nightFurry: #0e1424;
$blueWhale: #042a41;

/* bnb score colors */
$azureRadiance: #0073fd;
$pastelGreen: #57e558;
$bnbGold: #f4d100;
$tangerine: #f98400;
$bnbRed: #fa0000;

/* CyberArk score colors*/
$cyberArkScoreLow: $alizarinCrimson;
$cyberArkScoreMedium: $energyYellow;
$cyberArkScoreHigh: $turquoiseBlue;

$saffronMango: #f8d049;
$jacksonPurple: #1b327c;
$brightPurple: #a52ff5;
$spaceDarkness: #0f1329;
$Vulcan: #0e131e;
/* score colors */
$scoreLow: $carnation;
$scoreMedium: $energyYellow;
$scoreHigh: $turquoiseBlue;

/* sal/priority colors */
$salLow: $cerulean;
$salMedium: $energyYellow;
$salHigh: $carnation;
$salCritical: $milanoRed;

/* checkbox inputs */
$target: $pictonBlue;
$average: $mediumPurple;
$actual: $claret;

/* select drop down colors */
$selectHighlight: #007bff;
$selectCancel: #d0021b;

/* Collection Screen  */
$collectionSectionBackground: #262a36;
$collectionDarkBlue: #060a1a;

/* Collection Screen  */
$userSettingsBackground: #262a36;

/* logo status card background */
$logoStatusCardBackground: #141826;
$arrow-circle-background: #0e85d0;
$collection-overview-text: #bec0c4;
$collection-overview-text-right: #6a6c74;

$white-dull: #ffffff24;
$peach: #e54d15;
$lightSlate: #1a1d28;
$peach: #e54d15;
$ruby-red: #be231b;
$punch-pink: #f53a82;
$darkShadow: #0c0e17;

// Jira modal
$modalBackground: #030715;
$modalShadow: #1c1c2b29;
$borderColor: #313443;
$cellBorder: #7a7b80;
$gradientColorTop: #008ed8;
$gradientColorBottom: #48a9dd;

// Borad Overview
$denimDark: #191c2b;
$blackcurrant: #0c0e17e6;
$circleBackground: #100101e6;
$ceruleanDeep: #008ca3;
$turquoiseBright: #0dffd0;
$cherryPie: #2c045b;
$blueDiamond: #36046f;
$purpleHeart: #7d28e0;
$bilobaFlower: #b887f0;
$electricViolet: #8a27ff;
$electricVioletLight: #8f31fd;
$dimGrey: #333232;
$magicMint: #91efb5;
$greenGaze: #04a542;
$cornHarvest: #93780c;
$tamarillo: #a01d17;

// Users settings
$blueZodiac: #0c2945;
$blueZodiac1: #0c1336;
$blueZodiacApprox: #0f1f3f;
$gunPowder: #424656;

// collection screen
$purpleCircle: #ae55ea;
$commentBackground: #0f111c;
$deepCove: #04122f;
// findings
$ebonyIntergrations: #090c1b;
$cloudBurst: #1e2943;
$cloudBurstDim: rgb(30, 41, 67, 0.58);
$cinderIntegrations: #13151e;
$goldenFizz: #dff726;
$rioGrande: #bcd30c;
$olio: #657200;
$blackPearlIntegrations: #020617;
$mountainMeadow: #1bd981;
$coolBlack: #10235f;
$cetaceanBlue: #02092c;
$mirageCheckBox: #191d2e;
$gaugeScore: #485376;
$bunker: #0e1015;
$eerieBlack: #161926;
$eerieBlack2: #101525;
$yankeesBlue: #131c36;
$Charade: #2e3241;
$Ebony: #0a0d1f;
$Tangelo: #f74909;
$bigStone3: #17253c;
$lightBorder: #787f8c;
$listActive: #051028;
$textAluminium: #abb0ba;
$navy: #0a1a3e;
$blueRibbon: #1261f7;
$mudGray: #6b6b77f5;
$mudGray: #6b6b77f5;
$rhino: #263053;
$gumental: #111930;
$magenta: #d228b0;
$magenta2: #ff10cf;
$magenta3: #b10da2;
$darkMagenta: #770b8e;
$darkMagenta2: #710a60;
$darkNavy: #010433;
$skyBlue: #0cc8f4;
$newBlack: #05070a;
$darkNavy1: #0b1c43;
$darkNavy2: #050a31;
$skyBlue: #0cc8f4;
$purpleIris: #620a75;
$cherryPie: #2b0855;
$deniumBlue: #60c4f8;
$lapisBlue: #193a77;
$chineseBlack2: #121619;
$midBlack: #061021;
$scollColor: #142548;
$midRed: #fa2318;
$TealishBlue: #0f1323;
$haiti1: #0b1027;
$goldenFizz1: #f1f840;
$bunker1: #12161b;
$grayChateau: #9d9fa3;
$lochmara1: #0080C3;
$sliver : #b8b8b8;
