@import './common';
@import '~@ng-select/ng-select/themes/default.theme.css';

* {
  font-family: $latoFont;
}

html,
body {
  width: 100%;
  height: 100%;
  font-family: $latoFont;
  overflow: hidden;

  ::-webkit-scrollbar,
  &::-webkit-scrollbar {
    width: 11px;
    height: 10px;
    background-color: transparent;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-track,
  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-thumb {
    background-color: $ebonyClayMedium;
    border-radius: 10px;
    border: 1px solid $manatee;
  }
}
.existing-file-modal {
  overflow-y: hidden !important;
  animation-name: ease-in-animation;
  animation-duration: 1s;
}

.disable-clicks {
  pointer-events: none;
}

@keyframes ease-in-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Animation for Ng-bootstrap modal for Rapid7 */
.rapid7-credentials-modal .modal-content {
  animation-name: ngBootstrapModalOpenAnimation;
  animation-duration: 0.3s;
}

@keyframes ngBootstrapModalOpenAnimation {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}

.cursor-pointer {
  cursor: pointer;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-area-wrapper  {
  padding-right: 10px;
  background-color: #0b1124;
  border-radius: 4px;
  margin-top: 1.5vh;
  .text-area-section {
    margin-top: 5px!important;
  }

}

.dropdown-wrapper {
  .ng-dropdown-panel {
    filter: none!important;
  }
}

ngb-modal-backdrop {
  z-index: 1050 !important;
}

ngb-datepicker {
  transform: translate(0px, 0px)!important;
}